/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #005C2E;
    border-color: #1861ac;
}


.search-sec {
    padding: 1rem;
}

.search-slt {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #55595c;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    height: calc(2.5rem + 2px) !important;
    border-radius: 7px;
}

.btn-search {
    color: #fff !important;
    background-color: #0d420c;
    border-color: #096331;
}
.wrn-btn {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    height: calc(2.5rem + 2px) !important;
    border-radius: 7px;
    background-color: #005C2E;
    border-color: #005C2E;
}

    .wrn-btn:hover {
        background-color: #00812E;
        border-color: #00812E;
    }

    @media (min-width: 992px) {
        .search-sec {
            position: relative;
            top: 20px;
            background: #c19e37;
        }
}

@media (max-width: 992px) {
    .search-sec {
        background: #c19e37;
        position: relative;
        top: 20px;
    }
}



h5 {
    color:#000;
    font-size:15px;
    text-transform:uppercase;
    font-weight:400;
}

h4 {
    font-size: 14px;
}

.banner {
    width: 100%;
    background-position: 100% 100%;
    background-size: cover;
    margin-top: -16px;
}

hr {border-top:2px solid #000;}

.titleblock {
    background: #005C2E;
    padding: 15px;
    padding-bottom:5px;
    padding-top:5px;
    text-align: left;
}

.titleblock h2 {
    color:#aa9758;
    margin-bottom:8px;
}

.green {
    background:#00812E;
}

.yellow {
    background: #F6B900;
}

.footer {
    background: #2C3137;
    padding: 15px;
    height: 60px;
    margin-top: 50px;
    border-top: 4px solid #F6B900;
}

.table th, .table td {
    padding: 0.25rem;
    font-size: 0.9rem;
}

.btn-success {color:#fff!important;}

.card-header {
    padding: 0.25rem 0.25rem;
    background-color: #1e7e34;
}

.btn-link {
    color: #fff!important;
}

.btn-link:hover {
    color: #fff!important;
}

.blue {
    background: #B3D3EA!important;
    color: #000;
}

#details {
    
    
}

.ant-spin-nested-loading {
    padding:55px;
}

.panel {
    background: #1E7E34 !important;
    padding: 12px 12px;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    border-bottom: 2px solid #fff;
}

.panel-body {
   
    margin-bottom: 10px;
    border-radius: 5px;
    margin-top: -1px;
    transition: 0.5s;
    overflow: hidden;
}

.ant-spin-nested-loading {
    padding: 0px !important;
    margin-top: 20px;
}

.ant-table-thead > tr > th {
    background: #353A40 !important;
    color:#fff;
}

.ant-table-tbody > tr > td {padding:2px 12px!important;}

.wbtn {
    height: 30px !important;
    padding:5px!important;
    line-height:5px;
    font-size:12px;
    width:50%;
}

.table-row-light td {
    background-color: #d4edda!important;
}

.table-row-dark {
    background-color: #fbfbfb;
}

table tr:nth-child(2n) td {
    background-color: #eee;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding:5px 12px;
}

.spin {position:relative!important;top:75px!important;}

.panel-body {
    display:none;
}

.show {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
    display:block;
}

.hide {
    opacity: 0;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 2s;
    display: none;
}

.fadein {
    animation-duration: 2s;
    transition: visibility 0s, opacity 0.5s linear;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: #005C2E;
    color: #aa9758;
    font-weight:bold;
    padding-left:20px;
}

.textwidget {color:#fff;}
.textwidget a{color:#fff;}

.pull-right {float:right;}
.black-box {
    background: #000;
    padding: 6px;
    margin-left: 10px;
    color: #fff;
    padding-left: 25px;
    padding-right: 25px;
    width: 135px;
    display: inline-block;
    margin-top:-4px;
}
hr  {margin-top:0.5rem;}
.white-box {
    background: #fff;
    padding: 0px;
    margin-left: 0px;
    color: #000;
    padding-left: 0px;
    padding-right: 25px;
    width: 135px;
    display: inline-block;
    margin-top: -4px;
}
.dotted {border-top:1px dashed #000;}
.caps {text-transform:uppercase;}

.mcont {min-height:450px;}
.ant-collapse-arrow {display:none!important;}
.bold {font-weight:700;color:#000;}
.ddate {
    margin-right:40px;
}
p {font-size:13px;font-weight:600;}
.nobold {font-weight:400;}

.ant-collapse-content-box {
    padding:0!important;
    padding-left:15px!important;
    padding-right:15px!important;
}
.pl {padding-left:100px;}
.pl30 {padding-left:11.5%;}
.navbar-light .navbar-nav .nav-link {font-size:18px;}
.sbold {font-weight:500;}
ol {
   
    font-weight: bold;
}

li span {
    font-weight: normal;
}

/*.steps {width:50%;margin-bottom:40px;}*/
.steps {
    width: 100%;
    margin-bottom: 20px;
}
.ml10 {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: -20px;
}
/*.steps {*/
    /*width: 50%;*/
    /*margin-bottom: 40px;
    padding: 0px 120px 0 120px;
}*/


.dis-show{
    display: block;
}

.modal-full-width .modal-lg {
    max-width:90%;
}

.modal-full-width iframe {
    height:80vh!important;
}

.signature-bg {
    background-color: #c19e37;
} 
.inactive-bg {
    background-color: grey;
    border-color: grey;
} 
.w-survey {
    width: 70% !important;
} 